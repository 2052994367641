import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

import HeaderSelectItem from "./HeaderSelectItem/HeaderSelectItem";
import LanguageSelector from "../shared/LanguageSelector/LanguageSelector";
import { SECTIONS, LINKEDIN_URL } from "../../assets/constants/constants";
import logo_cartozinc from "../../assets/img/logo_cartozinc.svg";
import icon_linkedin from "../../assets/img/icon_linkedin2.svg";
import { t } from "../../utils/globalUtils";

import "./Header.css";

type HeaderProps = {
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	options: any;
};

const Header = ({ selectedSection, setSelectedSection, options }: HeaderProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const getSectionStyle = (section: string) => {
		if (section === selectedSection) {
			return {
				borderBottom: "1px solid #fff",
			};
		}
	};

	return (
		<div id="header">
			<div id="headerLeft">
				<img
					id="headerLeftImg"
					className="pointer"
					src={logo_cartozinc}
					onClick={() => setSelectedSection("home")}
				/>
			</div>

			<div id="headerCenter">
				{SECTIONS.map((section, idx) =>
					section === "products" ? (
						<HeaderSelectItem
							section={section}
							selectedSection={selectedSection}
							setSelectedSection={setSelectedSection}
							options={options}
							className="headerSection headerSelectItemGlobal"
						/>
					) : (
						<div
							key={idx}
							className="headerSection"
							style={getSectionStyle(section)}
							onClick={() => setSelectedSection(section)}
						>
							{t(translations, selectedLanguage, section)}
						</div>
					)
				)}
			</div>

			<div id="headerRight">
				<a href={LINKEDIN_URL} target="_blank">
					<img id="headerRightImg" className="pointer" src={icon_linkedin} />
				</a>
				<LanguageSelector />
			</div>
		</div>
	);
};
export default Header;
