import { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../shared/Button/Button";
import { t } from "../../../../utils/globalUtils";
import { logInWithEmailAndPassword } from "../../../../firebase/authenticationService";

import "./Login.css";

type LoginProps = {
	closingFunction: () => void;
};

const Login = ({ closingFunction }: LoginProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const login = () => {
		logInWithEmailAndPassword(email, password)
			.then(closingFunction)
			.catch((err) => console.log(err));
	};

	return (
		<div id="login">
			<FontAwesomeIcon
				className="iconButton pointer"
				icon={faChevronLeft}
				onClick={closingFunction}
			/>

			<div className="content">
				<div id="loginForm">
					<div>
						<div className="loginFormTitle">
							{t(translations, selectedLanguage, "email")}
						</div>
						<input
							className="loginFormInput border borderRadius"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div>
						<div className="loginFormTitle">
							{t(translations, selectedLanguage, "password")}
						</div>
						<input
							type="password"
							className="loginFormInput border borderRadius"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<Button
						text={t(translations, selectedLanguage, "login")}
						onClick={login}
						className="loginButton light"
					/>
				</div>
			</div>
		</div>
	);
};
export default Login;
