import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyBrtY-jPEEa77xTWx9iGOg2-8KfYHbnfRk",
	authDomain: "flc-firebase.firebaseapp.com",
	projectId: "flc-firebase",
	storageBucket: "flc-firebase.appspot.com",
	messagingSenderId: "951179150332",
	appId: "1:951179150332:web:95325217d5d54e23132879",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage();
