import { useState, useEffect, Dispatch, SetStateAction } from "react";
import Select from "react-select";
import {
	Editor,
	EditorState,
	ContentState,
	RichUtils,
	convertToRaw,
	convertFromRaw,
} from "draft-js";
import createStyles from "draft-js-custom-styles";

import { customStyles, fontSizes } from "./TextEditorUtils";

import "./TextEditor.css";

const { styles, customStyleFn } = createStyles(["font-size"]);
const DEFAULT_FONTSIZE_VALUE = fontSizes[4];

type TextEditorProps = {
	setFormattedText?: Dispatch<SetStateAction<string | undefined>>;
	setUnformattedText?: Dispatch<SetStateAction<string | undefined>>;
	formattedText?: string;
	displayToolbar?: boolean;
	className?: string;
};

const TextEditor = ({
	setFormattedText,
	setUnformattedText,
	formattedText,
	displayToolbar = true,
	className,
}: TextEditorProps) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	useEffect(() => {
		if (formattedText) {
			try {
				const contentState = convertFromRaw(JSON.parse(formattedText));
				setEditorState(EditorState.createWithContent(contentState));
			} catch (e) {
				setEditorState(
					EditorState.createWithContent(ContentState.createFromText(formattedText))
				);
			}
		}
	}, [formattedText]);

	useEffect(() => {
		if (setFormattedText)
			setFormattedText(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
		if (setUnformattedText) setUnformattedText(getUnformattedText());
	}, [editorState]);

	const getUnformattedText = () => {
		return editorState.getCurrentContent().getPlainText("\u0001");
	};

	const changeFontSize = (e: any) => {
		const newEditorState = styles.fontSize.remove(editorState);
		setEditorState(styles.fontSize.add(newEditorState, e.value));
	};

	const bold = (e: any) => {
		e.preventDefault();
		const nextState = RichUtils.toggleInlineStyle(editorState, "BOLD");
		setEditorState(nextState);
	};

	const italic = (e: any) => {
		e.preventDefault();
		const nextState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
		setEditorState(nextState);
	};

	const underline = (e: any) => {
		e.preventDefault();
		const nextState = RichUtils.toggleInlineStyle(editorState, "UNDERLINE");
		setEditorState(nextState);
	};

	return (
		<div className={`textEditorContainer ${className}`}>
			{setFormattedText && displayToolbar && (
				<div className="textEditorToolbar">
					<Select
						styles={customStyles}
						defaultValue={DEFAULT_FONTSIZE_VALUE}
						options={fontSizes}
						onChange={changeFontSize}
					/>
					<button className="pointer" onClick={bold}>
						B
					</button>
					<button className="pointer" onClick={italic}>
						I
					</button>
					<button className="pointer" onClick={underline}>
						U
					</button>
				</div>
			)}

			<div className={`textEditor${setFormattedText && " border borderRadius"}`}>
				<Editor
					readOnly={!setFormattedText}
					customStyleFn={customStyleFn}
					editorState={editorState}
					onChange={setEditorState}
				/>
			</div>
		</div>
	);
};
export default TextEditor;
