import { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Box, Tab, Tabs } from "@mui/material";

import Languages from "./Languages/Languages";
import Translations from "./Translations/Translations";
import { t } from "../../../utils/globalUtils";

import "./AdminPage.css";

type AdminPageProps = {
	closingFunction: () => void;
};

const AdminPage = ({ closingFunction }: AdminPageProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);
	const isUserAdmin = useSelector((state: any) => state.isUserAdmin.value);

	const [selectedSectionIdx, setSelectedSectionIdx] = useState(isUserAdmin ? 0 : 1);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedSectionIdx(newValue);
	};

	return (
		<div id="adminPageContainer">
			<FontAwesomeIcon
				className="iconButton adminPageBackButton pointer"
				icon={faChevronLeft}
				onClick={closingFunction}
			/>

			<div id="adminPageContent">
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
						marginBottom: "30px",
						width: "fit-content",
					}}
				>
					<Tabs value={selectedSectionIdx} onChange={handleChange}>
						{isUserAdmin && (
							<Tab label={t(translations, selectedLanguage, "languages")} />
						)}
						<Tab label={t(translations, selectedLanguage, "translations")} />
					</Tabs>
				</Box>
				{isUserAdmin && (
					<div hidden={selectedSectionIdx !== 0} className="adminPageSection">
						<Languages />
					</div>
				)}
				<div hidden={selectedSectionIdx !== 1} className="adminPageSection">
					<Translations />
				</div>
			</div>
		</div>
	);
};
export default AdminPage;
