import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";

import Button from "../../../shared/Button/Button";
import { Language, Translation } from "../../../../types/types";
import { t } from "../../../../utils/globalUtils";
import {
	addTranslation,
	updateTranslation,
	removeTranslation,
} from "../../../../redux/translations";
import {
	addTranslationDocument,
	editTranslationDocument,
	deleteTranslationDocument,
} from "../../../../firebase/translationsServices";

import "./Translations.css";

const Translations = () => {
	const dispatch = useDispatch();

	const languages = useSelector((state: any) => state.languages.value);
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);
	const isUserAdmin = useSelector((state: any) => state.isUserAdmin.value);

	const [translationToAdd, setTranslationToAdd] = useState<Translation>({ key: "" });

	const add = () => {
		if (translationToAdd?.key) {
			addTranslationDocument(translationToAdd).then((id) => {
				translationToAdd.id = id;
				dispatch(addTranslation(translationToAdd));
			});
		}
	};

	const update = (translation: Translation, key: string, value: string) => {
		dispatch(
			updateTranslation({ keyValue: translation.key, updatedKey: key, updatedValue: value })
		);
		const newTranslation = { ...translation };
		newTranslation[key] = value;
		editTranslationDocument(translation.id, newTranslation);
	};

	const remove = (translation: Translation) => {
		dispatch(removeTranslation(translation.key));
		deleteTranslationDocument(translation.id);
	};

	return (
		<div id="translations">
			{isUserAdmin && (
				<div id="addTranslation">
					<div
						className="translationsGrid translationsTitles"
						style={{ gridTemplateColumns: `repeat(${1 + languages.length}, 1fr)` }}
					>
						<div className="keyTitle">key</div>
						{languages.map((language: Language) => (
							<div>{language.code}</div>
						))}
					</div>
					<div
						className="translationsGrid"
						style={{ gridTemplateColumns: `repeat(${1 + languages.length}, 1fr)` }}
					>
						<TextField
							size="small"
							InputProps={{
								sx: {
									fontSize: "13px",
									fontWeight: "bold",
								},
							}}
							value={translationToAdd?.key}
							onChange={(e) =>
								setTranslationToAdd((prevState) => ({
									...prevState,
									["key"]: e.target.value,
								}))
							}
						/>
						{languages.map((language: Language) => (
							<TextField
								size="small"
								InputProps={{
									sx: {
										fontSize: "13px",
									},
								}}
								value={translationToAdd?.[language.code]}
								onChange={(e) =>
									setTranslationToAdd((prevState) => ({
										...prevState,
										[language.code]: e.target.value,
									}))
								}
							/>
						))}
					</div>
					<Button
						text={t(translations, selectedLanguage, "addTranslation")}
						onClick={add}
						className="blue addTranslationButton"
					/>
				</div>
			)}

			<div id="translationsList">
				<div
					className="translationsGrid translationsTitles"
					style={{ gridTemplateColumns: `repeat(${2 + languages.length}, 1fr)` }}
				>
					<div className="keyTitle">key</div>
					{languages.map((language: Language) => (
						<div>{language.code}</div>
					))}
				</div>
				<div id="translationsRows">
					{translations.map((translation: Translation) => (
						<div
							className="translationsGrid"
							style={{ gridTemplateColumns: `repeat(${2 + languages.length}, 1fr)` }}
						>
							<TextField
								size="small"
								InputProps={{
									sx: {
										fontSize: "13px",
										fontWeight: "bold",
									},
								}}
								disabled={!isUserAdmin}
								value={translation.key}
								onChange={(e) => update(translation, "key", e.target.value)}
							/>
							{languages.map((language: Language) => (
								<TextField
									size="small"
									InputProps={{
										sx: {
											fontSize: "13px",
										},
									}}
									value={translation[language.code] || ""}
									onChange={(e) =>
										update(translation, language.code, e.target.value)
									}
								/>
							))}
							<div className="translationDeleteButtonContainer">
								{isUserAdmin && (
									<FontAwesomeIcon
										className="pointer"
										icon={faXmark}
										onClick={() => remove(translation)}
									/>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default Translations;
