import { useState } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";

import Button from "../shared/Button/Button";
import { isMobileDevice, t } from "../../utils/globalUtils";

import "./Contact.css";

type ContactProps = {
	darkMode?: boolean;
};

const lightTheme = createTheme({
	palette: {
		mode: "light",
	},
});
const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

const Contact = ({ darkMode }: ContactProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [isEmailHasBeenSent, setIsEmailHasBeenSent] = useState(false);

	const isEmail = (string: string) => {
		const regex =
			/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
		return regex.test(string);
	};

	const sendMail = () => {
		if (isEmailHasBeenSent || isLoading) return;
		setIsLoading(true);
		fetch("https://europe-west3-flc-firebase.cloudfunctions.net/sendMail", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				name,
				email,
				message,
			}),
		})
			.then((res) => {
				if (res.ok) {
					toast(t(translations, selectedLanguage, "successEmail"));
					setIsEmailHasBeenSent(true);
				}
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<div className="container">
			<div id="contactContent" className="content">
				<div id="contactForm">
					<div id="contactTitle" className={isMobileDevice() ? "title" : "bigTitle"}>
						{t(translations, selectedLanguage, "contactTitle")}
					</div>
					<div id="contactRow">
						<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
							<TextField
								className="smallInput"
								variant="standard"
								inputProps={{
									style: { fontFamily: "cardinalphoto-regular" },
								}}
								placeholder={t(translations, selectedLanguage, "contactName")}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</ThemeProvider>
						<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
							<TextField
								className="contactEmailTextField smallInput"
								variant="standard"
								inputProps={{
									style: { fontFamily: "cardinalphoto-regular" },
								}}
								placeholder={t(translations, selectedLanguage, "contactEmail")}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</ThemeProvider>
					</div>
					<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
						<TextField
							className="contactMessageTextField"
							multiline
							minRows={8}
							fullWidth
							inputProps={{
								style: { fontFamily: "cardinalphoto-regular" },
							}}
							placeholder={t(translations, selectedLanguage, "yourMessage")}
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</ThemeProvider>
					<Button
						text={t(translations, selectedLanguage, "contactVerb")}
						onClick={sendMail}
						className={`contactButton title ${darkMode ? "dark" : "blueBackground"}`}
					/>
				</div>

				<div id="sliderContainer">
					<ClipLoader loading={isLoading} color={darkMode ? "#f4fefe" : "#000"} />
				</div>

				<Toaster />
			</div>
		</div>
	);
};
export default Contact;
