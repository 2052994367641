import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TextField from "@mui/material/TextField";
import Select from "react-select";

import TextEditor from "../../shared/TextEditor/TextEditor";
import Button from "../../shared/Button/Button";
import { Language, Post } from "../../../types/types";
import { t } from "../../../utils/globalUtils";
import {
	addPostDocument,
	addPostImgFile,
	deletePostImgFile,
	editPostDocument,
	getPostImgFile,
} from "../../../firebase/postService";

type PostFormProps = {
	backFunction: () => void;
	post?: Post;
};

const PostForm = ({ backFunction, post }: PostFormProps) => {
	const languages = useSelector((state: any) => state.languages.value);
	const languagesOptions = languages.map((language: Language) => ({
		value: language.code,
		label: language.code,
	}));
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [title, setTitle] = useState<string>();
	const [subTitle, setSubTitle] = useState<string>();
	const [language, setLanguage] = useState<string>();
	const [selectedImgFile, setSelectedImgFile] = useState();
	const [formattedContent, setFormattedContent] = useState<string>();
	const [unformattedContent, setUnformattedContent] = useState<string>();
	const [imgPreview, setImgPreview] = useState<string>();

	useEffect(() => {
		post && initPostToEditData();
	}, [post]);

	const initPostToEditData = () => {
		if (post) {
			post.title && setTitle(post.title);
			post.subTitle && setSubTitle(post.subTitle);
			post.language && setLanguage(post.language);
			post.content && setFormattedContent(post.content);
			post.id && getPostImgFile(post.id).then((url) => setImgPreview(url));
		}
	};

	const selectImgFile = (e: any) => {
		if (!e.target.files.length) return;
		else setSelectedImgFile(e.target.files[0]);
	};

	useEffect(() => {
		if (!selectedImgFile) return;
		const objectUrl = URL.createObjectURL(selectedImgFile);
		setImgPreview(objectUrl);
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedImgFile]);

	const addPost = () => {
		if (!title || !language) return;
		const post: Post = {
			title,
			date: Math.floor(Date.now() / 1000),
			language,
		};
		if (subTitle) post.subTitle = subTitle;
		if (unformattedContent) post.content = formattedContent;
		addPostDocument(post).then((documentId) => {
			if (selectedImgFile) addPostImgFile(selectedImgFile, documentId);
			backFunction();
		});
	};

	const editPost = () => {
		if (!post?.id || !title || !language) return;
		const newPostData: Post = {
			title,
			language,
		};
		if (subTitle) newPostData.subTitle = subTitle;
		if (unformattedContent) newPostData.content = formattedContent;
		editPostDocument(post.id, newPostData).then(() => {
			if (selectedImgFile) {
				deletePostImgFile(post.id).then(() => addPostImgFile(selectedImgFile, post.id));
			}
			backFunction();
		});
	};

	return (
		<div className="container">
			<FontAwesomeIcon
				className="iconButton backButton pointer"
				icon={faChevronLeft}
				onClick={backFunction}
			/>

			<div className="content">
				<div className="form">
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "title")}
						</div>
						<TextField
							multiline
							fullWidth
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "subTitle")}
						</div>
						<TextField
							multiline
							fullWidth
							value={subTitle}
							onChange={(e) => setSubTitle(e.target.value)}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "language")}
						</div>
						<Select
							className="formSelect"
							options={languagesOptions}
							value={languagesOptions.find(
								(option: { value: string; label: string }) =>
									option.value === language
							)}
							onChange={(e) => setLanguage(e?.value)}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "image")}
						</div>
						<label htmlFor="postFormInputImgFile" className="button">
							{t(translations, selectedLanguage, "addImage")}
						</label>
						<input
							type="file"
							id="postFormInputImgFile"
							className="formHiddenInputFile"
							accept="image/*"
							onChange={selectImgFile}
						/>
						{imgPreview && <img src={imgPreview} className="formImg" />}
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "content")}
						</div>
						<TextEditor
							setFormattedText={setFormattedContent}
							setUnformattedText={setUnformattedContent}
							formattedText={post?.content}
						/>
					</div>
					{post ? (
						<Button
							text={t(translations, selectedLanguage, "editPost")}
							onClick={editPost}
							className="formButton blue"
						/>
					) : (
						<Button
							text={t(translations, selectedLanguage, "addPost")}
							onClick={addPost}
							className="formButton blue"
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default PostForm;
