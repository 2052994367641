import { Translation } from "../types/types";

interface CustomDateTimeFormatOptions extends Intl.DateTimeFormatOptions {
	locale?: string;
}

export const getDateFromEpoch = (epochDate: number) => {
	return new Date(epochDate * 1000);
};

export const getFormattedDate = (epochDate: number) => {
	const date = getDateFromEpoch(epochDate);
	date.toLocaleString("fr-FR", { timeZone: "Europe/Paris" });
	const day = date.getDate();
	const month = new Intl.DateTimeFormat("fr-FR", {
		month: "long",
		locale: "fr-FR",
	} as CustomDateTimeFormatOptions).format(date);
	const cuttedMonth = month.slice(0, 3) + ".";
	const year = date.getFullYear();
	return `${day} ${cuttedMonth} ${year}`;
};

export const isMobileDevice = () => {
	const UA = navigator.userAgent;
	const isMobileDevice =
		/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
		/\b(Android|Windows Phone)\b/i.test(UA);
	return isMobileDevice;
};

export const t = (translations: Translation[], language: string, key: string) => {
	const translation = translations.find((transl) => transl.key === key);
	if (translation?.[language]) return translation[language];
	else return key;
};
