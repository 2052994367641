import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ResourcePreview from "./ResourcePreview/ResourcePreview";
import ResourceForm from "./ResourceForm/ResourceForm";
import Button from "../shared/Button/Button";
import { Resource } from "../../types/types";
import { PDF_SECTIONS } from "../../assets/constants/constants";
import { isMobileDevice, t } from "../../utils/globalUtils";
import { subscribeToResourceCollection, getResources } from "../../firebase/resourceService";

import "./Resources.css";

const mobileDevice = isMobileDevice();

const Resources = () => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);
	const isUserAdmin = useSelector((state: any) => state.isUserAdmin.value);

	const [resources, setResources] = useState<Resource[]>();
	const [isResourceFormDisplayed, setIsResourceFormDisplayed] = useState(false);
	const [resourceToEdit, setResourceToEdit] = useState<Resource | null>(null);

	useEffect(() => {
		subscribeToResourceCollection((collectionCount) => {
			getResources().then((resourcesData) => setResources(resourcesData as Resource[]));
		});
	}, []);

	return (
		<div className="container">
			{!isResourceFormDisplayed && !resourceToEdit && (
				<div className="content">
					{isUserAdmin && (
						<Button
							text={t(translations, selectedLanguage, "addResource")}
							onClick={() => setIsResourceFormDisplayed(true)}
							className="addPostButton blue"
						/>
					)}

					<div id="resources" style={{ top: isUserAdmin ? "55px" : "20px" }}>
						<div id="resourcesHeader">
							<div
								id="resourcesTitle"
								className={mobileDevice ? "title" : "bigTitle"}
							>
								{t(translations, selectedLanguage, "resourcesTitle")}
							</div>
							<div>{t(translations, selectedLanguage, "resourcesDescription")}</div>
						</div>

						{PDF_SECTIONS?.map((section, idx) => (
							<div className="resourcesSection">
								<div className="title resourcesSectionTitle">
									{t(translations, selectedLanguage, section)}
								</div>
								<div
									className="resourcesSectionPreviews"
									style={{
										gridTemplateColumns: `repeat(${
											isMobileDevice() ? "1" : "3"
										}, 1fr)`,
									}}
								>
									{resources?.map(
										(resource) =>
											resource.sectionId === idx + 1 &&
											resource.language === selectedLanguage && (
												<ResourcePreview
													key={resource.id}
													resource={resource}
													setResourceToEdit={setResourceToEdit}
												/>
											)
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			)}

			{isResourceFormDisplayed && (
				<ResourceForm backFunction={() => setIsResourceFormDisplayed(false)} />
			)}

			{resourceToEdit && (
				<ResourceForm
					backFunction={() => setResourceToEdit(null)}
					resource={resourceToEdit}
				/>
			)}
		</div>
	);
};
export default Resources;
