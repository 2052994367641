import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";

import Header from "./components/Header/Header";
import HomeHeader from "./components/Header/HomeHeader/HomeHeader";
import MobileHeader from "./components/Header/MobileHeader/MobileHeader";
import MobileHomeHeader from "./components/Header/MobileHomeHeader/MobileHomeHeader";
import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import Blog from "./components/Blog/Blog";
import Resources from "./components/Resources/Resources";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Login from "./components/Footer/authentication/Login/Login";
import AdminPage from "./components/Footer/admin/AdminPage";
import { Language, Translation, User } from "./types/types";
import { PRODUCTS, SECTIONS } from "./assets/constants/constants";
import { isMobileDevice } from "./utils/globalUtils";
import { auth } from "./firebase/firebase";
import { getLanguages, subscribeToLanguageCollection } from "./firebase/languagesServices";
import { getTranslations } from "./firebase/translationsServices";
import { getAdminUsers } from "./firebase/adminUserService";
import { setLanguages } from "./redux/languages";
import { setSelectedLanguage } from "./redux/selectedLanguage";
import { setTranslations } from "./redux/translations";
import { setIsUserAdmin } from "./redux/isUserAdmin";

import "./App.css";

const path = window.location.pathname.substring(1);

const App = () => {
	const dispatch = useDispatch();

	const [user] = useAuthState(auth);

	const [adminUsers, setAdminUsers] = useState<User[]>([]);
	const [selectedSection, setSelectedSection] = useState(
		SECTIONS.includes(path) ? path : SECTIONS[0]
	);
	const [selectedProduct, setSelectedProduct] = useState(PRODUCTS[0]);
	const [isLoginPageDisplayed, setIsLoginPageDisplayed] = useState(false);
	const [isAdminPageDisplayed, setIsAdminPageDisplayed] = useState(false);

	const products = [
		{
			title: PRODUCTS[0],
			function: () => setSelectedProduct(PRODUCTS[0]),
		},
		{
			title: PRODUCTS[1],
			function: () => setSelectedProduct(PRODUCTS[1]),
		},
	];

	const renderComponent = () => {
		setUrlRoute();
		switch (selectedSection) {
			case "home":
				return (
					<Home
						setSelectedSection={setSelectedSection}
						setSelectedProduct={setSelectedProduct}
					/>
				);
			case "products":
				return <Products selectedProduct={selectedProduct} />;
			case "resources":
				return <Resources />;
			case "blog":
				return <Blog />;
			case "contact":
				return <Contact />;
			default:
				return null;
		}
	};

	const setUrlRoute = () => {
		const urlParams = new URLSearchParams(document.location.search);
		const languageUrlParam = urlParams.get("lng");
		const urlWithPath = `${window.location.origin}/${selectedSection}${
			languageUrlParam ? `?lng=${languageUrlParam}` : ""
		}`;
		window.history.pushState(null, "", urlWithPath);
	};

	useEffect(() => {
		getAdminUsers().then((adminUsersData) => setAdminUsers(adminUsersData as User[]));
		getLanguagesFromDatabase();
		getTranslationsFromDatabase();
	}, []);

	useEffect(() => {
		if (user && adminUsers.length) {
			if (adminUsers.find((adminUser) => adminUser.uid === user.uid))
				dispatch(setIsUserAdmin(true));
			else dispatch(setIsUserAdmin(false));
		} else dispatch(setIsUserAdmin(false));
	}, [user, adminUsers]);

	const getLanguagesFromDatabase = () => {
		subscribeToLanguageCollection(() => {
			getLanguages().then((languagesData) => {
				dispatch(setLanguages(languagesData as Language[]));
				getLanguage(languagesData as Language[]);
			});
		});
	};

	const getLanguage = (savedLanguages: Language[]) => {
		let language = "fr";
		const urlParams = new URLSearchParams(document.location.search);
		const urlParamLanguage = urlParams.get("lng");
		if (urlParamLanguage && isLanguageSaved(urlParamLanguage, savedLanguages))
			language = urlParamLanguage;
		else {
			const navigatorLanguage = navigator.language.substring(
				0,
				navigator.language.indexOf("-")
			);
			if (navigatorLanguage && isLanguageSaved(navigatorLanguage, savedLanguages))
				language = navigatorLanguage;
		}
		dispatch(setSelectedLanguage(language));
	};

	const isLanguageSaved = (language: string, languages: Language[]) => {
		return languages.find((lng) => lng.code === language) ? true : false;
	};

	const getTranslationsFromDatabase = () => {
		getTranslations().then((translationsData) =>
			dispatch(setTranslations(translationsData as Translation[]))
		);
	};

	return (
		<div id="app">
			{!isLoginPageDisplayed && !isAdminPageDisplayed && (
				<>
					{isMobileDevice() ? (
						selectedSection === SECTIONS[0] ? (
							<MobileHomeHeader
								selectedSection={selectedSection}
								setSelectedSection={setSelectedSection}
								options={products}
							/>
						) : (
							<MobileHeader
								selectedSection={selectedSection}
								setSelectedSection={setSelectedSection}
								options={products}
							/>
						)
					) : selectedSection === SECTIONS[0] ? (
						<HomeHeader
							selectedSection={selectedSection}
							setSelectedSection={setSelectedSection}
							options={products}
						/>
					) : (
						<Header
							selectedSection={selectedSection}
							setSelectedSection={setSelectedSection}
							options={products}
						/>
					)}
					<div
						id="body"
						style={{ overflow: selectedSection !== SECTIONS[0] ? "auto" : "" }}
					>
						{renderComponent()}
					</div>
					<Footer
						user={user}
						selectedSection={selectedSection}
						setSelectedSection={setSelectedSection}
						options={products}
						setIsLoginPageDisplayed={setIsLoginPageDisplayed}
						setIsAdminPageDisplayed={setIsAdminPageDisplayed}
					/>
				</>
			)}
			{isLoginPageDisplayed && (
				<Login closingFunction={() => setIsLoginPageDisplayed(false)} />
			)}
			{isAdminPageDisplayed && (
				<AdminPage closingFunction={() => setIsAdminPageDisplayed(false)} />
			)}
		</div>
	);
};
export default App;
