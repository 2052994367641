import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import TextField from "@mui/material/TextField";
import Select from "react-select";

import Button from "../../shared/Button/Button";
import TextEditor from "../../shared/TextEditor/TextEditor";
import { Language, Resource } from "../../../types/types";
import { PDF_SECTIONS } from "../../../assets/constants/constants";
import { t } from "../../../utils/globalUtils";
import {
	addResourceDocument,
	addResourceImgFile,
	addResourcePdfFile,
	getResourceImgFile,
	getResourcePdfFile,
	editResourceDocument,
	deleteResourceImgFile,
	deleteResourcePdfFile,
} from "../../../firebase/resourceService";

import "./ResourceForm.css";

type PostFormProps = {
	backFunction: () => void;
	resource?: Resource;
};

const pdfSectionsOptions = PDF_SECTIONS.map((section, idx) => ({ value: idx + 1, label: section }));

const ResourceForm = ({ backFunction, resource }: PostFormProps) => {
	const languages = useSelector((state: any) => state.languages.value);
	const languagesOptions = languages.map((language: Language) => ({
		value: language.code,
		label: language.code,
	}));
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [title, setTitle] = useState<string>();
	const [formattedSubTitle, setFormattedSubTitle] = useState<string>();
	const [unformattedSubTitle, setUnformattedSubTitle] = useState<string>();
	const [language, setLanguage] = useState<string>();
	const [selectedImgFile, setSelectedImgFile] = useState();
	const [imgPreview, setImgPreview] = useState<string>();
	const [selectedPdfFile, setSelectedPdfFile] = useState();
	const [pdfPreview, setPdfPreview] = useState<string>();
	const [pdfDefaultPageNb, setPdfDefaultPageNb] = useState<number>();
	const [sectionId, setSectionId] = useState<number>();
	const [position, setPosition] = useState<number>();

	useEffect(() => {
		resource && initResourceToEditData();
	}, [resource]);

	const initResourceToEditData = () => {
		if (resource) {
			resource.title && setTitle(resource.title);
			resource.subTitle && setFormattedSubTitle(resource.subTitle);
			resource.pdfDefaultPageNb && setPdfDefaultPageNb(resource.pdfDefaultPageNb);
			resource.sectionId && setSectionId(resource.sectionId);
			resource.position && setPosition(resource.position);
			resource.language && setLanguage(resource.language);
			resource.id && getResourceImgFile(resource.id).then((url) => setImgPreview(url));
			resource.id && getResourcePdfFile(resource.id).then((url) => setPdfPreview(url));
		}
	};

	const selectImgFile = (e: any) => {
		if (!e.target.files.length) return;
		else setSelectedImgFile(e.target.files[0]);
	};

	useEffect(() => {
		if (!selectedImgFile) return;
		const objectUrl = URL.createObjectURL(selectedImgFile);
		setImgPreview(objectUrl);
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedImgFile]);

	const selectPdfFile = (e: any) => {
		if (!e.target.files.length) return;
		else setSelectedPdfFile(e.target.files[0]);
	};

	useEffect(() => {
		if (!selectedPdfFile) return;
		const objectUrl = URL.createObjectURL(selectedPdfFile);
		setPdfPreview(objectUrl);
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedPdfFile]);

	const addResource = () => {
		if (!title || sectionId === undefined || !language) return;
		const resourceData: Resource = {
			title,
			sectionId,
			language,
		};
		if (unformattedSubTitle) resourceData.subTitle = formattedSubTitle;
		if (pdfDefaultPageNb) resourceData.pdfDefaultPageNb = pdfDefaultPageNb;
		if (position) resourceData.position = position;
		addResourceDocument(resourceData).then((documentId) => {
			if (selectedImgFile) addResourceImgFile(selectedImgFile, documentId);
			if (selectedPdfFile) addResourcePdfFile(selectedPdfFile, documentId);
			backFunction();
		});
	};

	const editResource = () => {
		if (!resource?.id || !title || sectionId === undefined || !language) return;
		const newResourceData: Resource = {
			title,
			sectionId,
			language,
		};
		if (unformattedSubTitle) newResourceData.subTitle = formattedSubTitle;
		if (pdfDefaultPageNb) newResourceData.pdfDefaultPageNb = pdfDefaultPageNb;
		if (position) newResourceData.position = position;
		editResourceDocument(resource.id, newResourceData).then(() => {
			if (selectedImgFile) {
				deleteResourceImgFile(resource.id).then(() =>
					addResourceImgFile(selectedImgFile, resource.id)
				);
			}
			if (selectedPdfFile) {
				deleteResourcePdfFile(resource.id).then(() =>
					addResourcePdfFile(selectedPdfFile, resource.id)
				);
			}
			backFunction();
		});
	};

	return (
		<div className="container">
			<FontAwesomeIcon
				className="iconButton backButton pointer"
				icon={faChevronLeft}
				onClick={backFunction}
			/>

			<div className="content">
				<div className="form">
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "title")}
						</div>
						<TextField
							multiline
							fullWidth
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "subTitle")}
						</div>
						<TextEditor
							setFormattedText={setFormattedSubTitle}
							setUnformattedText={setUnformattedSubTitle}
							formattedText={resource?.subTitle}
							displayToolbar={false}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "language")}
						</div>
						<Select
							className="formSelect"
							options={languagesOptions}
							value={languagesOptions.find(
								(option: { value: string; label: string }) =>
									option.value === language
							)}
							onChange={(e) => setLanguage(e?.value)}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "image")}
						</div>
						<label htmlFor="resourceFormInputImgFile" className="button">
							{t(translations, selectedLanguage, "addImage")}
						</label>
						<input
							type="file"
							id="resourceFormInputImgFile"
							className="formHiddenInputFile"
							accept="image/*"
							onChange={selectImgFile}
						/>
						{imgPreview && <img src={imgPreview} className="formImg" />}
					</div>
					<div className="formRow">
						<div className="formTitle">{t(translations, selectedLanguage, "pdf")}</div>
						<label htmlFor="resourceFormInputPdfFile" className="button">
							{t(translations, selectedLanguage, "addPdf")}
						</label>
						<input
							type="file"
							id="resourceFormInputPdfFile"
							className="formHiddenInputFile"
							accept=".pdf"
							onChange={selectPdfFile}
						/>
						{pdfPreview && <embed src={pdfPreview} className="formEmbed" />}
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "pdfDefaultPageNb")}
						</div>
						<TextField
							type="number"
							className="formNbInput"
							sx={{
								"& .MuiInputBase-root": {
									height: 30,
								},
							}}
							value={pdfDefaultPageNb}
							onChange={(e) => setPdfDefaultPageNb(parseInt(e.target.value))}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "pdfSection")}
						</div>
						<Select
							className="formSelect"
							options={pdfSectionsOptions}
							value={pdfSectionsOptions.find(
								(section) => section.value === sectionId
							)}
							onChange={(e) => setSectionId(e?.value)}
						/>
					</div>
					<div className="formRow">
						<div className="formTitle">
							{t(translations, selectedLanguage, "position")}
						</div>
						<TextField
							type="number"
							className="formNbInput"
							sx={{
								"& .MuiInputBase-root": {
									height: 30,
								},
							}}
							value={position}
							onChange={(e) => setPosition(parseInt(e.target.value))}
						/>
					</div>
					{resource ? (
						<Button
							text={t(translations, selectedLanguage, "editResource")}
							onClick={editResource}
							className="formButton blue"
						/>
					) : (
						<Button
							text={t(translations, selectedLanguage, "addResource")}
							onClick={addResource}
							className="formButton blue"
						/>
					)}
				</div>
			</div>
		</div>
	);
};
export default ResourceForm;
