import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Language } from "../types/types";

export const languagesSlice = createSlice({
	name: "languages",
	initialState: {
		value: [] as Language[],
	},
	reducers: {
		setLanguages: (state, action: PayloadAction<Language[]>) => {
			state.value = action.payload;
		},
		addLanguage: (state, action: PayloadAction<Language>) => {
			state.value.push(action.payload);
		},
		setLanguageId: (state, action: PayloadAction<{ code: string; id: string }>) => {
			const language = state.value.find((language) => language.code === action.payload.code);
			if (language) language.id = action.payload.id;
		},
		removeLanguage: (state, action: PayloadAction<string>) => {
			state.value = state.value.filter((language) => language.code !== action.payload);
		},
	},
});

export const { setLanguages, addLanguage, setLanguageId, removeLanguage } = languagesSlice.actions;

export default languagesSlice.reducer;
