import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

import HeaderSelectItem from "../HeaderSelectItem/HeaderSelectItem";
import { getHomeBackgroundImgFile } from "../../../firebase/allService";
import { SECTIONS } from "../../../assets/constants/constants";
import logo_cartozinc from "../../../assets/img/logo_cartozinc2.svg";
import { t } from "../../../utils/globalUtils";

import "./HomeHeader.css";

type HeaderProps = {
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	options: any;
};

const HomeHeader = ({ selectedSection, setSelectedSection, options }: HeaderProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [homeBackgroundImgSrc, setHomeBackgroundImgSrc] = useState<string>();

	const getSectionStyle = (section: string) => {
		if (section === selectedSection) {
			return {
				borderBottom: "1px solid #fff",
			};
		}
	};

	useEffect(() => {
		getHomeBackgroundImgFile().then((fileUrl) => setHomeBackgroundImgSrc(fileUrl));
	}, []);

	return (
		<div id="homeHeader" style={{ backgroundImage: `url(${homeBackgroundImgSrc})` }}>
			<div id="homeHeaderTop">
				<img id="homeHeaderTopImg" src={logo_cartozinc} />
			</div>

			<div id="homeHeaderCenter">
				{SECTIONS.map((section, idx) =>
					section === "products" ? (
						<HeaderSelectItem
							section={section}
							selectedSection={selectedSection}
							setSelectedSection={setSelectedSection}
							options={options}
							className="homeHeaderSection title headerSelectItemHome"
						/>
					) : (
						<div
							key={idx}
							className="homeHeaderSection title"
							style={getSectionStyle(section)}
							onClick={() => setSelectedSection(section)}
						>
							{t(translations, selectedLanguage, section)}
						</div>
					)
				)}
			</div>
		</div>
	);
};
export default HomeHeader;
