import { useEffect, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

import Button from "../../shared/Button/Button";
import ManagementButton from "../../shared/ManagementButton/ManagementButton";
import { Post } from "../../../types/types";
import { getFormattedDate, t } from "../../../utils/globalUtils";
import { getPostImgFile, deletePostDocument } from "../../../firebase/postService";

import "./PostPreview.css";

type PostPreviewProps = {
	posts: Post[][];
	columnIdx: number;
	postIdx: number;
	setPosts: Dispatch<SetStateAction<Post[][] | []>>;
	onClickFunction: Dispatch<SetStateAction<Post | undefined>>;
	setPostToEdit: Dispatch<SetStateAction<Post | null>>;
};

const PostPreview = ({
	posts,
	columnIdx,
	postIdx,
	setPosts,
	onClickFunction,
	setPostToEdit,
}: PostPreviewProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);
	const isUserAdmin = useSelector((state: any) => state.isUserAdmin.value);

	const postData = posts[columnIdx][postIdx];
	const managementActions = [
		{
			title: t(translations, selectedLanguage, "edit"),
			function: () => setPostToEdit(postData),
		},
		{
			title: t(translations, selectedLanguage, "delete"),
			function: () => deletePostDocument(postData.id),
			confirmation: true,
		},
	];

	useEffect(() => {
		if (postData.id) {
			getPostImgFile(postData.id).then((fileUrl) => {
				const newPosts = [...posts];
				newPosts[columnIdx][postIdx].imgUrl = fileUrl;
				setPosts(newPosts);
			});
		}
	}, []);

	return (
		<div className="postPreview border">
			{postData.imgUrl && <img className="postPreviewImg" src={postData.imgUrl} alt="" />}
			{isUserAdmin && <ManagementButton actions={managementActions} />}
			<div className="postPreviewContent">
				{postData.date && (
					<div className="postPreviewDate smallText">
						{getFormattedDate(postData.date)}
					</div>
				)}
				<div className="title postPreviewTitle postPreviewText">{postData.title}</div>
				{postData.subTitle && <div className="postPreviewText">{postData.subTitle}</div>}
				<div className="postPreviewButtonContainer">
					<Button
						text={t(translations, selectedLanguage, "seePost")}
						onClick={() => onClickFunction(postData)}
						className="blue borderRadius4"
					/>
				</div>
			</div>
		</div>
	);
};
export default PostPreview;
