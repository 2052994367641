import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { PRODUCTS } from "../../assets/constants/constants";
import { getProductImgFile } from "../../firebase/allService";
import { isMobileDevice, t } from "../../utils/globalUtils";

import "./Products.css";

type ProductsProps = {
	selectedProduct: string;
};

const productsImgSrc = {
	language: "",
	cartozinc: "",
	filter: "",
};

const Products = ({ selectedProduct }: ProductsProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [imgSrc1, setImgSrc1] = useState<string>();
	const [imgSrc2, setImgSrc2] = useState<string>();

	useEffect(() => {
		if (!selectedLanguage) return;
		if (!productsImgSrc.cartozinc || productsImgSrc.language !== selectedLanguage) {
			getProductImgFile(`cartozinc${selectedLanguage}`).then((fileUrl) => {
				setImgSrc1(fileUrl);
				productsImgSrc.cartozinc = fileUrl;
				productsImgSrc.language = selectedLanguage;
			});
		}
		if (!productsImgSrc.filter || productsImgSrc.language !== selectedLanguage) {
			getProductImgFile(`filter${selectedLanguage}`).then((fileUrl) => {
				setImgSrc2(fileUrl);
				productsImgSrc.filter = fileUrl;
				productsImgSrc.language = selectedLanguage;
			});
		}
	}, [selectedLanguage]);

	const getImgSrc = () => {
		switch (selectedProduct) {
			case PRODUCTS[0]:
				return productsImgSrc.cartozinc || imgSrc1;
			case PRODUCTS[1]:
				return productsImgSrc.filter || imgSrc2;
		}
	};

	return (
		<div className="container">
			<div className="content">
				<div className="product">
					<div id="productHeader">
						<div className={`productTitle ${isMobileDevice() ? "title" : "bigTitle"}`}>
							{selectedProduct}
						</div>
						<div className="productSubTitle">
							<div className="productSubTitlePrefix">
								{t(translations, selectedLanguage, "by")}
							</div>
							<div> F.L.C.`</div>
						</div>
					</div>
					<div className="productImgContainer">
						<img className="productImg" src={getImgSrc()} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default Products;
