import {
	query,
	collection,
	getDocs,
	addDoc,
	onSnapshot,
	DocumentData,
	doc,
	deleteDoc,
} from "firebase/firestore";

import { db } from "./firebase";
import { Language } from "../types/types";

const LANGUAGE_COLLECTION_NAME = "language";

export const subscribeToLanguageCollection = (callback: (collectionCount: number) => void) => {
	onSnapshot(collection(db, LANGUAGE_COLLECTION_NAME), (collection): void => {
		callback(collection.size);
	});
};

export const getLanguages = async () => {
	const collectionRef = collection(db, LANGUAGE_COLLECTION_NAME);
	const q = query(collectionRef);
	const querySnapshot = await getDocs(q);
	const languages: DocumentData[] = [];
	querySnapshot.forEach((doc) => {
		const docData = doc.data();
		docData.id = doc.id;
		languages.push(docData);
	});
	return languages;
};

export const addLanguageDocument = (language: Language) => {
	return new Promise<string>((resolve, reject) => {
		addDoc(collection(db, LANGUAGE_COLLECTION_NAME), language).then((docRef) => {
			resolve(docRef.id);
		});
	});
};

export const deleteLanguageDocument = (languageId: string | undefined) => {
	if (!languageId) return;
	const docRef = doc(db, LANGUAGE_COLLECTION_NAME, languageId);
	deleteDoc(docRef);
};
