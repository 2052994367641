import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "@mui/material";

import Button from "../../../shared/Button/Button";
import { Language } from "../../../../types/types";
import { t } from "../../../../utils/globalUtils";
import {
	addLanguageDocument,
	deleteLanguageDocument,
} from "../../../../firebase/languagesServices";
import { setLanguageId } from "../../../../redux/languages";

import "./Languages.css";

const Languages = () => {
	const dispatch = useDispatch();

	const languages = useSelector((state: any) => state.languages.value);
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [languageCodeToAdd, setLanguageCodeToAdd] = useState<string>();
	const [languageNameToAdd, setLanguageNameToAdd] = useState<string>();

	const addLanguage = () => {
		if (languageCodeToAdd && languageNameToAdd)
			addLanguageDocument({ code: languageCodeToAdd, name: languageNameToAdd }).then((id) =>
				dispatch(setLanguageId({ code: languageCodeToAdd, id }))
			);
	};

	const deleteLanguage = (id: string | undefined) => {
		deleteLanguageDocument(id);
	};

	return (
		<div id="languages">
			<div id="languagesList">
				{languages?.map((language: Language) => (
					<div className="language">
						<div className="languageCode">{language.code}</div>
						<div>{language.name}</div>
						<FontAwesomeIcon
							className="languageDeleteButton pointer"
							icon={faXmark}
							onClick={() => deleteLanguage(language.id)}
						/>
					</div>
				))}
			</div>
			<div id="addLanguage">
				<div id="addLanguageForm">
					<div>{t(translations, selectedLanguage, "code")}</div>
					<div>{t(translations, selectedLanguage, "name")}</div>
					<TextField
						size="small"
						InputProps={{
							sx: {
								fontSize: "13px",
							},
						}}
						value={languageCodeToAdd}
						onChange={(e) => setLanguageCodeToAdd(e.target.value)}
					/>
					<TextField
						size="small"
						InputProps={{
							sx: {
								fontSize: "13px",
							},
						}}
						value={languageNameToAdd}
						onChange={(e) => setLanguageNameToAdd(e.target.value)}
					/>
				</div>
				<Button
					text={t(translations, selectedLanguage, "addLanguage")}
					onClick={addLanguage}
					className="blue"
				/>
			</div>
		</div>
	);
};
export default Languages;
