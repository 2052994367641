import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";

import { auth } from "./firebase";

export const signUpWithEmailAndPassword = (email: string, password: string) => {
	return new Promise((resolve, reject) => {
		createUserWithEmailAndPassword(auth, email, password)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const logInWithEmailAndPassword = (email: string, password: string) => {
	return new Promise((resolve, reject) => {
		signInWithEmailAndPassword(auth, email, password)
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const logout = () => {
	signOut(auth);
};
