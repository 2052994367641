import { DocumentData, collection, getDocs, query } from "firebase/firestore";

import { db } from "./firebase";

const ADMIN_COLLECTION_NAME = "admin";

export const getAdminUsers = async () => {
	const collectionRef = collection(db, ADMIN_COLLECTION_NAME);
	const q = query(collectionRef);
	const querySnapshot = await getDocs(q);
	const adminUsers: DocumentData[] = [];
	querySnapshot.forEach((doc) => {
		const docData = doc.data();
		docData.id = doc.id;
		adminUsers.push(docData);
	});
	return adminUsers;
};
