import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const selectedLanguageSlice = createSlice({
	name: "selectedLanguage",
	initialState: {
		value: "fr",
	},
	reducers: {
		setSelectedLanguage: (state, action: PayloadAction<string>) => {
			state.value = action.payload;
		},
	},
});

export const { setSelectedLanguage } = selectedLanguageSlice.actions;

export default selectedLanguageSlice.reducer;
