import {
	query,
	collection,
	getDocs,
	addDoc,
	onSnapshot,
	DocumentData,
	doc,
	updateDoc,
	deleteDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";

import { db, storage } from "./firebase";
import { Resource } from "../types/types";

const RESOURCE_COLLECTION_NAME = "resource";

export const subscribeToResourceCollection = (callback: (collectionCount: number) => void) => {
	onSnapshot(collection(db, RESOURCE_COLLECTION_NAME), (collection): void => {
		callback(collection.size);
	});
};

export const getResources = async () => {
	const collectionRef = collection(db, RESOURCE_COLLECTION_NAME);
	const q = query(collectionRef);
	const querySnapshot = await getDocs(q);
	const resources: DocumentData[] = [];
	querySnapshot.forEach((doc) => {
		const docData = doc.data();
		docData.id = doc.id;
		resources.push(docData);
	});
	const sortedResources = resources.sort((a, b) => {
		if (!a.position && !b.position) return 0;
		else if (!a.position) return 1;
		else if (!b.position) return -1;
		else return a.position - b.position;
	});
	return sortedResources;
};

export const addResourceDocument = (resource: Resource) => {
	return new Promise<string>((resolve, reject) => {
		addDoc(collection(db, RESOURCE_COLLECTION_NAME), resource).then((docRef) => {
			resolve(docRef.id);
		});
	});
};

export const getResourceImgFile = (imgFileName: string) => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, `resourceImg/${imgFileName}`))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const addResourceImgFile = (imgFile: File, imgFileName: string | undefined) => {
	const storageRef = ref(storage, `resourceImg/${imgFileName}`);
	uploadBytes(storageRef, imgFile);
};

export const getResourcePdfFile = (pdfFileName: string) => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, `resourcePdf/${pdfFileName}`))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const addResourcePdfFile = (pdfFile: File, pdfFileName: string | undefined) => {
	const storageRef = ref(storage, `resourcePdf/${pdfFileName}`);
	uploadBytes(storageRef, pdfFile);
};

export const editResourceDocument = (resourceId: string | undefined, resourceData: Resource) => {
	return new Promise<void>((resolve, reject) => {
		if (!resourceId) return;
		const docRef = doc(db, RESOURCE_COLLECTION_NAME, resourceId);
		updateDoc(docRef, resourceData).then(resolve).catch(reject);
	});
};

export const deleteResourceDocument = (resourceId: string | undefined) => {
	if (!resourceId) return;
	const docRef = doc(db, RESOURCE_COLLECTION_NAME, resourceId);
	deleteDoc(docRef);
	deleteResourceImgFile(resourceId);
	deleteResourcePdfFile(resourceId);
};

export const deleteResourceImgFile = (imgFileName: string | undefined) => {
	return new Promise<void>((resolve, reject) => {
		const storageRef = ref(storage, `resourceImg/${imgFileName}`);
		deleteObject(storageRef).then(resolve).catch(resolve);
	});
};

export const deleteResourcePdfFile = (pdfFileName: string | undefined) => {
	return new Promise<void>((resolve, reject) => {
		const storageRef = ref(storage, `resourcePdf/${pdfFileName}`);
		deleteObject(storageRef).then(resolve).catch(resolve);
	});
};
