import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { User } from "firebase/auth";

import HeaderSelectItem from "../Header/HeaderSelectItem/HeaderSelectItem";
import Button from "../shared/Button/Button";
import logo_cartozinc from "../../assets/img/logo_cartozinc.svg";
import { t } from "../../utils/globalUtils";
import { logout } from "../../firebase/authenticationService";

import "./Footer.css";

type FooterProps = {
	user: User | null | undefined;
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	options: any;
	setIsLoginPageDisplayed: Dispatch<SetStateAction<boolean>>;
	setIsAdminPageDisplayed: Dispatch<SetStateAction<boolean>>;
};

const infoMail = "info@cartozinc.com";

const Footer = ({
	user,
	selectedSection,
	setSelectedSection,
	options,
	setIsLoginPageDisplayed,
	setIsAdminPageDisplayed,
}: FooterProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const scrollToTop = () => {
		const homeElement = document.getElementById("app");
		homeElement?.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	return (
		<div id="footer">
			<div id="footerImgContainer">
				<img
					id="footerImg"
					className="pointer"
					src={logo_cartozinc}
					onClick={() => setSelectedSection("home")}
				/>
			</div>
			<div id="footerMenu" className="subTitle">
				<HeaderSelectItem
					section={"products"}
					selectedSection={selectedSection}
					setSelectedSection={setSelectedSection}
					options={options}
					className="pointer"
					chevron={false}
					callback={() => scrollToTop()}
				/>
				<div
					className="pointer"
					onClick={() => {
						setSelectedSection("contact");
						scrollToTop();
					}}
				>
					{t(translations, selectedLanguage, "contact")}
				</div>
			</div>
			<div id="infoMail">{infoMail}</div>
			<div id="rights" className="smallText">{`© Cartozinc l'hermétique respectueux - ${t(
				translations,
				selectedLanguage,
				"rights"
			)}`}</div>
			<div className="redirectButtonContainer">
				<Button
					text={
						user
							? t(translations, selectedLanguage, "logout")
							: t(translations, selectedLanguage, "login")
					}
					onClick={() => {
						if (user) logout();
						else setIsLoginPageDisplayed(true);
					}}
					className="loginRedirectButton dark"
				/>
			</div>
			{user && (
				<div className="redirectButtonContainer">
					<Button
						text={t(translations, selectedLanguage, "adminPage")}
						onClick={() => setIsAdminPageDisplayed(true)}
						className="adminRedirectButton dark"
					/>
				</div>
			)}
		</div>
	);
};
export default Footer;
