import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Language } from "../../../types/types";
import { isMobileDevice } from "../../../utils/globalUtils";
import { setSelectedLanguage } from "../../../redux/selectedLanguage";

import "./LanguageSelector.css";

const LanguageSelector = () => {
	const dispatch = useDispatch();

	const languages = useSelector((state: any) => state.languages.value);
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);

	const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);

	const handleMenuToggle = () => {
		setIsLanguageMenuOpen((prevOpen) => !prevOpen);
	};

	const handleMenuClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setIsLanguageMenuOpen(false);
	};

	return (
		<div
			className="languageSelector pointer"
			style={{ marginLeft: isMobileDevice() ? "10px" : "15px" }}
			ref={anchorRef}
			onClick={handleMenuToggle}
		>
			<div>{selectedLanguage}</div>
			<FontAwesomeIcon icon={faChevronDown} />
			<Popper
				open={isLanguageMenuOpen}
				anchorEl={anchorRef.current}
				placement="bottom-start"
				sx={{ zIndex: 1 }}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleMenuClose}>
								<MenuList autoFocusItem={isLanguageMenuOpen}>
									{languages.map((language: Language) => (
										<MenuItem
											onClick={() =>
												dispatch(setSelectedLanguage(language.code))
											}
										>
											{language.code}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};
export default LanguageSelector;
