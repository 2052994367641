import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { User } from "firebase/auth";

import PostPreview from "./PostPreview/PostPreview";
import Post from "./Post/Post";
import PostForm from "./PostForm/PostForm";
import Button from "../shared/Button/Button";
import { Post as PostType } from "../../types/types";
import { isMobileDevice, t } from "../../utils/globalUtils";
import {
	subscribeToPostCollection,
	getPostsOrderedByDate,
	getPostsCountForLanguage,
} from "../../firebase/postService";

import "./Blog.css";

const PAGE_LIMIT = 12;

const Blog = () => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);
	const isUserAdmin = useSelector((state: any) => state.isUserAdmin.value);

	const [allPosts, setAllPosts] = useState<PostType[][]>([]);
	const [visiblePosts, setVisiblePosts] = useState<PostType[][]>([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [maxPageNumber, setMaxPageNumber] = useState(1);
	const [lastDocument, setLastDocument] = useState<
		QueryDocumentSnapshot<DocumentData, DocumentData> | undefined
	>();
	const [selectedPostData, setSelectedPostData] = useState<PostType>();
	const [isPostFormDisplayed, setIsPostFormDisplayed] = useState(false);
	const [postToEdit, setPostToEdit] = useState<PostType | null>(null);
	const [hasLanguageChanged, setHasLanguageChanged] = useState(false);

	useEffect(() => {
		subscribeToPostCollection((collectionCount) => {
			setPageNumber(1);
			manageVisiblePosts();
		});
	}, []);

	useEffect(() => {
		resetPostsData();
		getPostsCountForLanguage(selectedLanguage).then((count) =>
			setMaxPageNumber(Math.ceil(count / PAGE_LIMIT))
		);
		setHasLanguageChanged(true);
	}, [selectedLanguage]);

	const resetPostsData = () => {
		setAllPosts([]);
		setVisiblePosts([]);
		setLastDocument(undefined);
		setPageNumber(1);
	};

	useEffect(() => {
		if (hasLanguageChanged) {
			manageVisiblePosts();
			setHasLanguageChanged(false);
		}
	}, [hasLanguageChanged]);

	useEffect(() => {
		manageVisiblePosts();
	}, [pageNumber]);

	const manageVisiblePosts = () => {
		if (allPosts.length > (pageNumber - 1) * PAGE_LIMIT) getPostsAlreadyLoaded();
		else getPostsFromDatabase();
	};

	const getPostsFromDatabase = () => {
		getPostsOrderedByDate(selectedLanguage, PAGE_LIMIT, lastDocument).then(
			({ postsData, lastDoc }) => {
				setLastDocument(lastDoc);
				let columns: PostType[][];
				const tempAllPosts = [...allPosts];
				if (isMobileDevice()) {
					columns = [[]];
					postsData.forEach((data: any) => {
						if (!allPosts?.includes(data)) tempAllPosts.push(data);
						columns[0].push(data);
					});
				} else {
					columns = [[], [], []];
					let i = 0;
					postsData.forEach((data: any) => {
						if (!allPosts?.includes(data)) tempAllPosts.push(data);
						if (i === 3) i = 0;
						columns[i].push(data);
						i++;
					});
				}
				setAllPosts(tempAllPosts);
				setVisiblePosts(columns);
			}
		);
	};

	const getPostsAlreadyLoaded = () => {
		let columns: PostType[][];
		const firstIdx = (pageNumber - 1) * PAGE_LIMIT;
		const lastIdx = pageNumber * PAGE_LIMIT;
		if (isMobileDevice()) {
			columns = [[]];
			allPosts.slice(firstIdx, lastIdx).forEach((data: any) => {
				columns[0].push(data);
			});
		} else {
			columns = [[], [], []];
			let i = 0;
			allPosts.slice(firstIdx, lastIdx).forEach((data: any) => {
				if (i === 3) i = 0;
				columns[i].push(data);
				i++;
			});
		}
		setVisiblePosts(columns);
	};

	return (
		<div className="container">
			{!selectedPostData && !isPostFormDisplayed && !postToEdit && (
				<div className="content">
					{isUserAdmin && (
						<Button
							text={t(translations, selectedLanguage, "addPost")}
							onClick={() => setIsPostFormDisplayed(true)}
							className="addPostButton blue"
						/>
					)}

					{visiblePosts?.map((column, columnIdx) => (
						<div
							key={columnIdx}
							className="postsColumn"
							style={{
								top: isUserAdmin ? "60px" : "20px",
								width: `${100 / visiblePosts.length}%`,
							}}
						>
							{column.map((post, postIdx) => (
								<PostPreview
									key={postIdx}
									posts={visiblePosts}
									columnIdx={columnIdx}
									postIdx={postIdx}
									setPosts={setVisiblePosts}
									onClickFunction={setSelectedPostData}
									setPostToEdit={setPostToEdit}
								/>
							))}
						</div>
					))}
				</div>
			)}

			{maxPageNumber > 1 && (
				<div id="blogFooter">
					<div id="pagesButtons">
						<FontAwesomeIcon
							className="iconButton pointer"
							style={{
								color: pageNumber === 1 ? "#aaa" : "#000",
								cursor: pageNumber === 1 ? "auto" : "pointer",
							}}
							icon={faChevronLeft}
							onClick={() => {
								if (pageNumber !== 1) setPageNumber(pageNumber - 1);
							}}
						/>
						<div>{`${pageNumber}/${maxPageNumber}`}</div>
						<FontAwesomeIcon
							className="iconButton pointer"
							style={{
								color: pageNumber === maxPageNumber ? "#aaa" : "#000",
								cursor: pageNumber === maxPageNumber ? "auto" : "pointer",
							}}
							icon={faChevronRight}
							onClick={() => {
								if (pageNumber !== maxPageNumber) setPageNumber(pageNumber + 1);
							}}
						/>
					</div>
				</div>
			)}

			{selectedPostData && (
				<Post
					postData={selectedPostData}
					backFunction={() => setSelectedPostData(undefined)}
				/>
			)}

			{isPostFormDisplayed && <PostForm backFunction={() => setIsPostFormDisplayed(false)} />}

			{postToEdit && <PostForm backFunction={() => setPostToEdit(null)} post={postToEdit} />}
		</div>
	);
};
export default Blog;
