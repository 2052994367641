import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import TextEditor from "../../shared/TextEditor/TextEditor";
import { getFormattedDate, isMobileDevice, t } from "../../../utils/globalUtils";
import { getAuthorImgFile } from "../../../firebase/allService";
import { Post as PostType } from "../../../types/types";

import "./Post.css";

type PostProps = {
	postData: PostType;
	backFunction: () => void;
};

const mobileDevice = isMobileDevice();

const Post = ({ postData, backFunction }: PostProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [authorImgSrc, setAuthorImgSrc] = useState<string>();

	useEffect(() => {
		getAuthorImgFile().then((fileUrl) => setAuthorImgSrc(fileUrl));
	}, []);

	return (
		<div className="container">
			<FontAwesomeIcon
				className="iconButton backButton pointer"
				icon={faChevronLeft}
				onClick={backFunction}
			/>

			<div className="content">
				<div id="postContainer" style={{ display: mobileDevice ? "block" : "flex" }}>
					<div
						id="postContent"
						style={{
							width: mobileDevice ? "100%" : "70%",
							marginBottom: mobileDevice ? "30px" : "",
						}}
					>
						{postData.date && (
							<div className="postRow">{getFormattedDate(postData.date)}</div>
						)}
						{postData.title && <div className="postRow bigTitle">{postData.title}</div>}
						{postData.subTitle && <div className="postRow">{postData.subTitle}</div>}
						{postData.imgUrl && (
							<img className="postRow postImg" src={postData.imgUrl} />
						)}
						{postData.content && <TextEditor formattedText={postData.content} />}
					</div>

					<div
						id="postAuthorSection"
						style={{
							width: mobileDevice ? "100%" : "30%",
							marginLeft: mobileDevice ? "" : "30px",
						}}
					>
						<div id="postAuthorContainer" className="borderRadius3">
							<div id="authorImgContainer" className="borderRadius3">
								<img id="authorImg" src={authorImgSrc} />
							</div>
							<div id="authorTitle">
								{t(translations, selectedLanguage, "authorTitle")}
							</div>
							<div id="authorText">
								{t(translations, selectedLanguage, "authorText")}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Post;
