export const SECTIONS: string[] = ["home", "products", "resources", "blog", "contact"];

export const PRODUCTS = ["CARTOZINC", "Filtre Fun/3"];

export const FLAGS: string[] = ["fr", "eu", "onu", "oaci", "iata"];

export const PDF_SECTIONS: string[] = ["conformity", "info"];

export const LINKEDIN_URL =
	"https://fr.linkedin.com/posts/fran%C3%A7oise-lavergne_cartozinc-une-innovation-fun%C3%A9raire-si-je-activity-7033802569713885184-RL0C?trk=public_profile_like_view";
