import {
	query,
	collection,
	getDocs,
	addDoc,
	onSnapshot,
	DocumentData,
	where,
	orderBy,
	limit,
	startAfter,
	doc,
	deleteDoc,
	updateDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";

import { db, storage } from "./firebase";
import { Post } from "../types/types";

const POST_COLLECTION_NAME = "post";

export const subscribeToPostCollection = (callback: (collectionCount: number) => void) => {
	onSnapshot(collection(db, POST_COLLECTION_NAME), (collection): void => {
		callback(collection.size);
	});
};

export const getPostsOrderedByDate = async (language: string, quantity: number, lastDoc: any) => {
	const collectionRef = collection(db, POST_COLLECTION_NAME);
	let q = query(collectionRef, where("language", "==", language), orderBy("date", "desc"));
	if (quantity) {
		if (lastDoc) {
			q = query(q, startAfter(lastDoc), limit(quantity));
		} else {
			q = query(q, limit(quantity));
		}
	}
	const querySnapshot = await getDocs(q);
	const posts: DocumentData[] = [];
	querySnapshot.forEach((doc) => {
		const docData = doc.data();
		docData.id = doc.id;
		posts.push(docData);
	});
	let newLastDoc;
	if (querySnapshot.docs.length > 0) {
		newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
	}
	return { postsData: posts, lastDoc: newLastDoc };
};

export const getPostsCountForLanguage = async (language: string) => {
	const collectionRef = collection(db, POST_COLLECTION_NAME);
	const q = query(collectionRef, where("language", "==", language));
	const querySnapshot = await getDocs(q);
	const count = querySnapshot.size;
	return count;
};

export const addPostDocument = (post: Post) => {
	return new Promise<string>((resolve, reject) => {
		addDoc(collection(db, POST_COLLECTION_NAME), post).then((docRef) => {
			resolve(docRef.id);
		});
	});
};

export const getPostImgFile = (imgFileName: string) => {
	return new Promise<string>((resolve, reject) => {
		getDownloadURL(ref(storage, `postImg/${imgFileName}`))
			.then((url) => resolve(url))
			.catch(() => {});
	});
};

export const addPostImgFile = (imgFile: File, imgFileName: string | undefined) => {
	const storageRef = ref(storage, `postImg/${imgFileName}`);
	uploadBytes(storageRef, imgFile);
};

export const editPostDocument = (postId: string | undefined, postData: Post) => {
	return new Promise<void>((resolve, reject) => {
		if (!postId) return;
		const docRef = doc(db, POST_COLLECTION_NAME, postId);
		updateDoc(docRef, postData).then(resolve).catch(reject);
	});
};

export const deletePostDocument = (postId: string | undefined) => {
	if (!postId) return;
	const docRef = doc(db, POST_COLLECTION_NAME, postId);
	deleteDoc(docRef);
	deletePostImgFile(postId);
};

export const deletePostImgFile = (imgFileName: string | undefined) => {
	return new Promise<void>((resolve, reject) => {
		const storageRef = ref(storage, `postImg/${imgFileName}`);
		deleteObject(storageRef).then(resolve).catch(resolve);
	});
};
