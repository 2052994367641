import {
	query,
	collection,
	getDocs,
	addDoc,
	onSnapshot,
	DocumentData,
	doc,
	deleteDoc,
	updateDoc,
} from "firebase/firestore";

import { db } from "./firebase";
import { Translation } from "../types/types";

const TRANSLATION_COLLECTION_NAME = "translation";

export const subscribeToTranslationCollection = (callback: (collectionCount: number) => void) => {
	onSnapshot(collection(db, TRANSLATION_COLLECTION_NAME), (collection): void => {
		callback(collection.size);
	});
};

export const getTranslations = async () => {
	const collectionRef = collection(db, TRANSLATION_COLLECTION_NAME);
	const q = query(collectionRef);
	const querySnapshot = await getDocs(q);
	const languages: DocumentData[] = [];
	querySnapshot.forEach((doc) => {
		const docData = doc.data();
		docData.id = doc.id;
		languages.push(docData);
	});
	return languages;
};

export const addTranslationDocument = (translation: Translation) => {
	return new Promise<string>((resolve, reject) => {
		addDoc(collection(db, TRANSLATION_COLLECTION_NAME), translation).then((docRef) => {
			resolve(docRef.id);
		});
	});
};

export const editTranslationDocument = (
	translationId: string | undefined,
	translationData: Translation
) => {
	return new Promise<void>((resolve, reject) => {
		if (!translationId) return;
		const docRef = doc(db, TRANSLATION_COLLECTION_NAME, translationId);
		updateDoc(docRef, translationData).then(resolve).catch(reject);
	});
};

export const deleteTranslationDocument = (translationId: string | undefined) => {
	if (!translationId) return;
	const docRef = doc(db, TRANSLATION_COLLECTION_NAME, translationId);
	deleteDoc(docRef);
};
