import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import HeaderSelectItem from "../HeaderSelectItem/HeaderSelectItem";
import LanguageSelector from "../../shared/LanguageSelector/LanguageSelector";
import { LINKEDIN_URL, SECTIONS } from "../../../assets/constants/constants";
import logo_cartozinc3 from "../../../assets/img/logo_cartozinc3.svg";
import logo_cartozinc2 from "../../../assets/img/logo_cartozinc.svg";
import icon_linkedin from "../../../assets/img/icon_linkedin2.svg";
import { t } from "../../../utils/globalUtils";

import "./MobileHeader.css";

type MobileHeaderProps = {
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	options: any;
};

const MobileHeader = ({ selectedSection, setSelectedSection, options }: MobileHeaderProps) => {
	const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

	return (
		<>
			<div id="mobileHeader">
				<div id="mobileHeaderLeft">
					<a href={LINKEDIN_URL} target="_blank">
						<img id="mobileHeaderLeftImg" src={icon_linkedin} />
					</a>
					<LanguageSelector />
				</div>

				<div id="mobileHeaderCenter">
					<img
						id="mobileHeaderImg"
						src={logo_cartozinc2}
						onClick={() => setSelectedSection("home")}
					/>
				</div>

				<div id="mobileHeaderRight">
					<FontAwesomeIcon
						className="mobileHeaderRightButton"
						icon={faBars}
						onClick={() => setIsMenuDisplayed(true)}
					/>
				</div>

				{isMenuDisplayed && (
					<MobileHeaderMenu
						selectedSection={selectedSection}
						setSelectedSection={setSelectedSection}
						setIsMenuDisplayed={setIsMenuDisplayed}
						options={options}
					/>
				)}
			</div>
		</>
	);
};
export default MobileHeader;

type MobileHeaderMenuProps = {
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	setIsMenuDisplayed: Dispatch<SetStateAction<boolean>>;
	options: any;
};

const MobileHeaderMenu = ({
	selectedSection,
	setSelectedSection,
	setIsMenuDisplayed,
	options,
}: MobileHeaderMenuProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const menuRef = useRef<HTMLDivElement | null>(null);

	const getSectionStyle = (section: string) => {
		if (section === selectedSection) {
			return {
				borderBottom: "1px solid #002279",
			};
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	const handleOutsideClick = (e: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(e.target as HTMLElement)) {
			setIsMenuDisplayed(false);
		}
	};

	return (
		<div id="mobileHeaderMenu" ref={menuRef}>
			<div id="mobileHeaderMenuImgContainer">
				<img id="mobileHeaderMenuImg" src={logo_cartozinc3} />
			</div>

			<div id="mobileHeaderMenuSectionsContainer">
				{SECTIONS.map((section, idx) =>
					section === "products" ? (
						<HeaderSelectItem
							section={section}
							selectedSection={selectedSection}
							setSelectedSection={setSelectedSection}
							options={options}
							className="section"
						/>
					) : (
						<div
							key={idx}
							className="section"
							style={getSectionStyle(section)}
							onClick={() => setSelectedSection(section)}
						>
							{t(translations, selectedLanguage, section)}
						</div>
					)
				)}
			</div>

			<div id="mobileHeaderMenuButtonContainer">
				<FontAwesomeIcon
					className="closeButton"
					icon={faXmark}
					onClick={() => setIsMenuDisplayed(false)}
				/>
			</div>
		</div>
	);
};
