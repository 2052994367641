import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const isUserAdminSlice = createSlice({
	name: "isUserAdmin",
	initialState: {
		value: false,
	},
	reducers: {
		setIsUserAdmin: (state, action: PayloadAction<boolean>) => {
			state.value = action.payload;
		},
	},
});

export const { setIsUserAdmin } = isUserAdminSlice.actions;

export default isUserAdminSlice.reducer;
