const selectHeight = "25px";

export const customStyles = {
    control: (provided: any) => ({
        ...provided,
        minHeight: selectHeight,
        height: selectHeight,
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        height: selectHeight,
        padding: "0 6px",
    }),
    input: (provided: any) => ({
        ...provided,
        margin: "0px",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
        height: selectHeight,
    }),
};

export const fontSizes = [
    { value: "8px", label: "8" },
    { value: "10px", label: "10" },
    { value: "12px", label: "12" },
    { value: "14px", label: "14" },
    { value: "16px", label: "16" },
    { value: "18px", label: "18" },
    { value: "20px", label: "20" },
    { value: "24px", label: "24" },
    { value: "28px", label: "28" },
    { value: "32px", label: "32" },
    { value: "38px", label: "38" },
];