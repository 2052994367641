import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import HeaderSelectItem from "../HeaderSelectItem/HeaderSelectItem";
import { getHomeBackgroundImgFile } from "../../../firebase/allService";
import { SECTIONS } from "../../../assets/constants/constants";
import logo_cartozinc from "../../../assets/img/logo_cartozinc2.svg";
import { t } from "../../../utils/globalUtils";

import "./MobileHomeHeader.css";

type MobileHeaderProps = {
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	options: any;
};

const MobileHomeHeader = ({ selectedSection, setSelectedSection, options }: MobileHeaderProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [homeBackgroundImgSrc, setHomeBackgroundImgSrc] = useState<string>();

	const getSectionStyle = (section: string) => {
		if (section === selectedSection) {
			return {
				borderBottom: "1px solid #fff",
			};
		}
	};

	useEffect(() => {
		getHomeBackgroundImgFile().then((fileUrl) => setHomeBackgroundImgSrc(fileUrl));
	}, []);

	const scrollToContent = () => {
		const homeElement = document.getElementById("home");
		homeElement?.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	return (
		<div id="mobileHomeHeader" style={{ backgroundImage: `url(${homeBackgroundImgSrc})` }}>
			<div id="mobileHomeHeaderTop">
				<img id="mobileHomeHeaderTopImg" src={logo_cartozinc} />
			</div>

			<div id="mobileHomeHeaderCenter">
				{SECTIONS.map((section, idx) =>
					section === "products" ? (
						<HeaderSelectItem
							section={section}
							selectedSection={selectedSection}
							setSelectedSection={setSelectedSection}
							options={options}
							className="mobileHomeHeaderSection title"
						/>
					) : (
						<div
							key={idx}
							className="mobileHomeHeaderSection title"
							style={getSectionStyle(section)}
							onClick={() => setSelectedSection(section)}
						>
							{t(translations, selectedLanguage, section)}
						</div>
					)
				)}
			</div>

			<FontAwesomeIcon
				id="mobileHomeHeaderChevron"
				icon={faChevronDown}
				onClick={scrollToContent}
			/>
		</div>
	);
};
export default MobileHomeHeader;
