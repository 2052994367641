import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Translation } from "../types/types";

export const translationsSlice = createSlice({
	name: "translations",
	initialState: {
		value: [] as Translation[],
	},
	reducers: {
		setTranslations: (state, action: PayloadAction<Translation[]>) => {
			state.value = action.payload;
		},
		addTranslation: (state, action: PayloadAction<Translation>) => {
			state.value.push(action.payload);
		},
		removeTranslation: (state, action: PayloadAction<string>) => {
			state.value = state.value.filter((translation) => translation.key !== action.payload);
		},
		updateTranslation: (
			state,
			action: PayloadAction<{ keyValue: string; updatedKey: string; updatedValue: string }>
		) => {
			const translation = state.value.find(
				(translation) => translation.key === action.payload.keyValue
			);
			if (translation) {
				translation[action.payload.updatedKey] = action.payload.updatedValue;
			}
		},
	},
});

export const { setTranslations, addTranslation, updateTranslation, removeTranslation } =
	translationsSlice.actions;

export default translationsSlice.reducer;
