import { configureStore } from "@reduxjs/toolkit";

import languagesReducer from "./languages";
import selectedLanguageReducer from "./selectedLanguage";
import translationsReducer from "./translations";
import isUserAdminReducer from "./isUserAdmin";

export default configureStore({
	reducer: {
		languages: languagesReducer,
		selectedLanguage: selectedLanguageReducer,
		translations: translationsReducer,
		isUserAdmin: isUserAdminReducer,
	},
});
