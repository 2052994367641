import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

import Button from "../../shared/Button/Button";
import ManagementButton from "../../shared/ManagementButton/ManagementButton";
import TextEditor from "../../shared/TextEditor/TextEditor";
import { Resource } from "../../../types/types";
import { t } from "../../../utils/globalUtils";
import {
	getResourceImgFile,
	getResourcePdfFile,
	deleteResourceDocument,
} from "../../../firebase/resourceService";

import "./ResourcePreview.css";

type ResourcePreviewProps = {
	resource: Resource;
	setResourceToEdit: Dispatch<SetStateAction<Resource | null>>;
};

const ResourcePreview = ({ resource, setResourceToEdit }: ResourcePreviewProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);
	const isUserAdmin = useSelector((state: any) => state.isUserAdmin.value);

	const [resourceImgUrl, setResourceImgUrl] = useState<string>();
	const [resourcePdfUrl, setResourcePdfUrl] = useState<string>();
	const managementActions = [
		{
			title: t(translations, selectedLanguage, "edit"),
			function: () => setResourceToEdit(resource),
		},
		{
			title: t(translations, selectedLanguage, "delete"),
			function: () => deleteResourceDocument(resource.id),
			confirmation: true,
		},
	];

	useEffect(() => {
		if (resource.id) {
			getResourceImgFile(resource.id).then((fileUrl) => {
				setResourceImgUrl(fileUrl);
			});
			getResourcePdfFile(resource.id).then((fileUrl) => {
				setResourcePdfUrl(fileUrl);
			});
		}
	}, []);

	const openPdf = () => {
		const pdfUrl = `${resourcePdfUrl}${
			resource.pdfDefaultPageNb && `#page=${resource.pdfDefaultPageNb}`
		}`;
		window.open(pdfUrl, "_blank");
	};

	return (
		<div className="resourcePreview borderRadius2">
			{isUserAdmin && <ManagementButton actions={managementActions} />}
			{resourceImgUrl && <img className="resourcePreviewImg" src={resourceImgUrl} alt="" />}
			<div className="resourcePreviewContent">
				<div className="resourcePreviewTitle">{resource.title}</div>
				{resource.subTitle && (
					<TextEditor formattedText={resource.subTitle} className="resourceText" />
				)}
			</div>
			<div className="resourcePreviewButtonContainer">
				<Button
					text={t(translations, selectedLanguage, "seeDocument")}
					onClick={openPdf}
					className="openPdfButton blue borderRadius4"
				/>
			</div>
		</div>
	);
};
export default ResourcePreview;
