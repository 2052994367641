import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import {
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@mui/material";
import { t } from "../../../utils/globalUtils";

import "./ManagementButton.css";

type ManagementButtonProps = {
	actions: Action[];
};

type Action = {
	title: string;
	function: () => void;
	confirmation?: boolean;
};

const ManagementButton = ({ actions }: ManagementButtonProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isDialogDisplayed, setIsDialogDisplayed] = useState(false);
	const [tempAction, setTempAction] = useState<Action | null>(null);
	const anchorRef = useRef<SVGSVGElement>(null);

	const handleMenuToggle = () => {
		setIsMenuOpen((prevOpen) => !prevOpen);
	};

	const handleMenuClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setIsMenuOpen(false);
	};

	const onMenuItemClick = (action: Action) => {
		if (action.confirmation) handleDialogDisplay(action);
		else action.function();
		setIsMenuOpen(false);
	};

	const handleDialogDisplay = (action: Action) => {
		setIsDialogDisplayed(true);
		setTempAction(action);
	};

	const handleDialogClose = () => {
		setIsDialogDisplayed(false);
	};

	const handleDialogConfirm = () => {
		tempAction?.function();
		handleDialogClose();
		setTempAction(null);
	};

	return (
		<div className="managementButtonContainer">
			<FontAwesomeIcon
				className="managementButton iconButton pointer"
				icon={faEllipsisVertical}
				ref={anchorRef}
				onClick={handleMenuToggle}
			/>

			<Popper
				open={isMenuOpen}
				anchorEl={anchorRef.current}
				placement="bottom-start"
				sx={{ zIndex: 1 }}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleMenuClose}>
								<MenuList autoFocusItem={isMenuOpen}>
									{actions.map((action) => (
										<MenuItem onClick={() => onMenuItemClick(action)}>
											{action.title}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>

			<Dialog open={isDialogDisplayed} onClose={handleDialogClose}>
				<DialogContent>
					<DialogContentText>
						{t(translations, selectedLanguage, "confirmDeletion")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose}>
						{t(translations, selectedLanguage, "no")}
					</Button>
					<Button onClick={handleDialogConfirm} autoFocus>
						{t(translations, selectedLanguage, "yes")}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default ManagementButton;
