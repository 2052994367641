import "./Button.css";

type ButtonProps = {
	text?: string;
	onClick?: () => void;
	className?: string;
};

const Button = ({ text, onClick, className }: ButtonProps) => {
	return (
		<div className={`button borderRadius3 ${className}`} onClick={onClick}>
			{text}
		</div>
	);
};
export default Button;
