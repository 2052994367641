import { useState, useRef, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { isMobileDevice, t } from "../../../utils/globalUtils";

import "./HeaderSelectItem.css";

type HeaderSelectItemProps = {
	section: string;
	selectedSection: string;
	setSelectedSection: Dispatch<SetStateAction<string>>;
	options: any;
	className?: string;
	chevron?: boolean;
	callback?: () => void;
};

const HeaderSelectItem = ({
	section,
	selectedSection,
	setSelectedSection,
	options,
	className,
	chevron = true,
	callback,
}: HeaderSelectItemProps) => {
	const selectedLanguage = useSelector((state: any) => state.selectedLanguage.value);
	const translations = useSelector((state: any) => state.translations.value);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);

	const getSectionStyle = (section: string) => {
		if (section === selectedSection && chevron) {
			return {
				borderBottom: `1px solid #${
					selectedSection !== "home" && isMobileDevice() ? "002279" : "fff"
				}`,
			};
		}
	};

	const handleMenuToggle = () => {
		setIsMenuOpen((prevOpen) => !prevOpen);
	};

	const handleMenuClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}
		setIsMenuOpen(false);
	};

	const onMenuItemClick = (option: any) => {
		option.function();
		setSelectedSection(section);
		setIsMenuOpen(false);
		if (callback) callback();
	};

	const getChevronStyle = () => {
		const style = {
			transform: "",
			color: "",
			marginRight: "",
			paddingRight: "",
		};
		if (isMobileDevice()) {
			if (selectedSection === "home") style.transform = "translate(-15px, -10px)";
			else {
				style.transform = "translate(-6px, 3px)";
				style.color = "#002279";
			}
		} else {
			if (selectedSection === "home") style.transform = "translate(3px, 1px)";
			else style.transform = "translate(5px, 1px)";
			style.marginRight = "15px !important";
			style.paddingRight = "35px !important";
		}
		return style;
	};

	return (
		<div className={`headerSelectItem ${className}`} style={getSectionStyle(section)}>
			<div ref={anchorRef} onClick={handleMenuToggle}>
				{t(translations, selectedLanguage, section)}
			</div>
			{chevron && (
				<FontAwesomeIcon
					className="headerChevron pointer"
					style={getChevronStyle()}
					icon={faChevronDown}
					onClick={handleMenuToggle}
				/>
			)}

			<Popper
				open={isMenuOpen}
				anchorEl={anchorRef.current}
				placement="bottom-start"
				sx={{ zIndex: 1 }}
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleMenuClose}>
								<MenuList autoFocusItem={isMenuOpen}>
									{options.map((option: any) => (
										<MenuItem onClick={() => onMenuItemClick(option)}>
											{option.title}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};
export default HeaderSelectItem;
